.mybody {
  background: '#E8E8E8';
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat', 'Arial';
  line-height: inherit;
}

.mydiv {
  height: 100%;
  position: relative;
  margin: 0;
}

.mycontent {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.newSideContext {
  flex-direction: row;
  position: relative;
  display: flex;
  min-height: 92vh;
}

#conteudo-lateral {
  width: 74%;
  float: left;
  margin-left: 20%;
  display: table;
  margin-top: 2%;
  padding-left: 1%;
}

@media screen and (max-width: 1024px) {
  #conteudo-lateral {
    width: 100%;
    margin-left: 5%;
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .mycontent {
    margin-left: 2%;
  }
}

.layout-bloco {
  border: 2px solid #d6d6d6;
  padding: 3%;
  max-width: 90%;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  min-height: 140px;
  margin-top: 20px;
}

.layout-bloco2 {
  border: 2px solid #d6d6d6;
  padding: 2%;
  max-width: 90%;
  margin-top: -20px;
  margin-bottom: 2%;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  min-height: 140px;
}

.myh1 {
  font-size: 19px;
  color: #555;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

#subtext {
  margin-top: 0px;
  font-size: 14px;
  color: #666;
}

.myhr {
  border: 1px solid #d7dadd;
  width: 95%;
}

.ptext {
  float: right;
  margin-top: -6%;
  color: #7e8e9f;
  text-transform: capitalize;
}

.select {
  width: 90px;
  height: 40px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background: rgba(219, 225, 232, 255);
  border: 0px;
  padding-left: 10px;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: rgba(219, 225, 232, 255) url(../../assets/skins/down_2_ek1.png)
    85.5% 50% / 13px no-repeat;
}

.tamanho-nome {
  min-width: 260px;
}
.tamanho-vencimento {
  padding-left: 30px !important;
}

#primeira .myth {
  color: #158175;
  padding-left: 3%;
  border-top: 0px;
  border-left: 0px;
  font-weight: bold;
  font-family: arial;
  font-size: 14px;
}

.icone-profile {
  float: left;
  margin-left: 15px;
  margin-right: 15px;
  border: 2px solid #1bb4a3;
  border-radius: 80px;
  width: 44px;
  height: 44px;
  display: table;
}

.nome {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

.data-consulta {
  text-align: left;
  color: #d0002a;
  margin: 10px;
  padding-left: 15px;
}

.tamanho-vencimento {
  padding-left: 30px !important;
}

.status-tamanho {
  margin: 10px;
  color: #158175;
  font-size: 16px;
  padding-left: 15px;
  font-family: arial;
}

.botao-do-status {
  padding: 12px;
  background: #fff;
  border: 1px solid #158175;
  color: #158175;
  border-radius: 4px;
  font-size: 13px;
  min-width: 150px;
}

.me-centra {
  text-align: center;
}

.ajusta-icone {
  transform: rotate(180deg);
  margin-left: 10px;
}

.wait {
  color: #f2c832 !important;
}

.danger {
  color: #d0002a !important;
}

.remarcar-btn {
  color: white;
  background-color: #007469;
  transition: all 0.3s ease-in-out;
  padding: 0.8rem 0.3rem;
  cursor: pointer;
  border-radius: 0.3rem;
}

.active {
  border-left: 2px solid #158175;
}

#sub-container {
  background: #fff;
  margin-top: 5%;
  width: 100%;
  border: 2px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.maior-profile {
  border: 4px solid #1bb4a3;
  border-radius: 80px;
  width: 108px;
  height: 108px;
  display: table;
  padding: 1%;
  margin-right: 5%;
}

.custo-consulta {
  margin-left: 0px;
  font-size: 13px;
}

#outro-perfil {
  padding-top: 5%;
  display: table;
  width: 45%;
  padding-left: 4%;
  float: left;
  text-align: left;
  padding-bottom: 1%;
}

.myh3 {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  white-space: nowrap;
  text-transform: uppercase;
}

#opcoes-bloco {
  float: left;
  width: 55%;
  padding-left: 3%;
  padding-top: 5%;
}

#rodape {
  display: table;
  width: 100%;
  background: #f5f5f5;
}

.mya {
  color: #1bb4a3;
}
#sub-rodape {
  padding: 3%;
  padding-top: 0;
}

#sub-next {
  width: 30%;
}

.correct {
  text-align: left;
  padding-left: 15px;
  color: #555;
}

#pago {
  background: #007469;
  color: #fff;
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
  margin-left: 15px;
}

.destaque-verde {
  color: #007469;
  font-size: 12px !important;
}

.destaque-vermelho {
  color: #d0002a;
  font-size: 12px !important;
}

.bb {
  width: 55%;
  float: left;
}

.sub-bb {
  width: 45%;
  float: right;
}

.meu-bloco-botao {
  background: url(../../assets/skins/rounded_rectangle_4_ek1.png);
  background-repeat: no-repeat;
  display: table;
  float: left;
  padding: 5%;
  color: #fff;
  margin-left: 6%;
  margin-top: 6%;
  background-size: 100%;
  text-align: center;
  cursor: pointer;
  padding-top: 2%;
  width: 90%;
}

.cancel-bloco-botao {
  background: url('../../assets/skins/rounded_rectangle_4_copy.png');
  background-repeat: no-repeat;
  display: table;
  float: left;
  padding: 5%;
  color: #fff;
  margin-left: 6%;
  margin-top: 6%;
  background-size: 100%;
  text-align: center;
  cursor: pointer;
  padding-top: 2%;
  width: 90%;
}

#botao-cancel {
  border: 2px solid #d0002a;
  padding: 1%;
  padding-top: 4%;
  padding-bottom: 4%;
  border-radius: 2px;
}

.sideForm2 {
  background-color: #fff;
  max-width: 100%;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  overflow: scroll;
  overflow-x: hidden;
}

.screen2 {
  width: 100%;
  height: 91.5%;
}

.input-modal {
  width: 100%;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  background-color: #fff;
}

.icone-profile {
  border: 2px solid #1bb4a3;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.card-body h6 {
  color: #007469;
}

.card-body p {
  font-size: 0.8rem;
}

.card-body p span {
  color: #007469;
  font-weight: bold;
}

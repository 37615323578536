#formContent {
max-width: 100% !important;
background: #fff !important;
width:100% !important;
box-shadow: 0 0px 0px 0 rgba(0,0,0,0.3) !important;
}
#conteudo-lateral {
width: 74% !important;
float: left !important;
margin-left: 14px !important;
height: 500px !important;
display: table !important;
margin-top: 25px !important;
padding-left: 30px !important;
}


#topo{

max-width: 100%;
background: #fff;
display: table;
width: 100%;
/*border-bottom: 1px solid rgba(188,199,211,255);*/

}


#logo{

margin-left: 15px;
width: 214px;
padding: 10px;
border-right: 1px solid rgba(188,199,211,255);
text-align: center;
float: left;

}

#painel{

float: left;
width: 50%; 

}


#menu-lateral{
width:250px;
background: #007469;
height:1049px;
float: left;   
}

.quadro{
float: left;
padding: 25px;
border-right: 1px solid rgba(188,199,211,255);   

}

#lateral{

width: 300px;
float: right;
padding-right: 27px;

}


.novoquadro{

border-left: 1px solid rgba(188,199,211,255);   
cursor: pointer;

}
.my-profile{

float: left;
margin-left: 15px;
margin-top: 15px;
margin-right: 15px;
border: 2px solid #1bb4a3;
border-radius: 80px;
width: 34px;
height: 34px;
display: table;
}

.p-left{

float: left;
width: 10px;    


}

.next-title{
font-size: 15px;
color: #555;   

}
.sub-title{

font-size: 12px;
color: #1bb4a3;

}
#corpo-botao{

position: absolute;
right: 0px;
top: 0px;
background: #eee;
padding: 10px;
height: 50px; 
cursor: pointer;

}

#corpo-botao:hover{

background: #ccc;   
transition:500ms;  
}


#corpo-botao img {
margin-top: 20px;
}

#botao-de-topo{

background: url(../../../assets/skins/rounded_rectangle_4_ek1.png);
background-repeat: no-repeat;
display: table;
float: left;
padding: 15px;
color: #fff;
margin-left: 20px;
margin-top: 9px;
background-size: 100%;
text-align: center;
cursor: pointer;    

}
#circle{

width: 18px;
height: 18px;
-ms-border-radius: 9px / 9px;
-o-border-radius: 9px / 9px;
-moz-border-radius: 9px / 9px;
-webkit-border-radius: 9px / 9px;
border-radius: 9px / 9px;
background: rgba(244,27,63,255);
position: absolute;
top: 13px;
margin-left: 7px;
color: #fff;
font-size: 11px;
text-align: center;

}
#icone-calendario{
float: left;
margin-right: 10px;   

}



#sub-menu{

float: left;
width: 100%;
display: table;
list-style-type: none;
color: #91B3AE;
padding-left: 0px;
margin-top: 0px;
overflow:hidden;

}


#sub-menu li{

padding-left: 45px;
width: 100%;
padding-top: 14px;
padding-bottom: 14px;  
cursor:pointer;
}

#sub-menu li:hover{
background: #006C63;
color:#fff;
transition:500ms;
}

#clock,#shape_8{

margin-right:10px;   
width: 14px;

}

#conteudo-lateral{

width: 74%;
float: left;
margin-left: 25px;
height: 500px;
display: table;
margin-top: 25px;
padding-left: 30px; 

}


#subtext{

margin-top: 0px;    
font-size: 14px;
color: #666;
}

hr{

border: 1px solid #D7DADD;  

}



.text-left{

text-align: left;   

}

.table{

margin-top: 27px;
background: url(../../../assets/skins/rectangle_21_copy_2_ek1.png);
font-weight: 100;  


}

#primeira th{

color: #158175;
padding-left: 15px;
border-top: 0px;
border-left: 0px;
font-weight: bold;
font-family: arial;
font-size: 14px;

}

.icone-profile{


float: left;
margin-left: 15px;
margin-right: 15px;
border: 2px solid #1bb4a3;
border-radius: 80px;
width: 44px;
height: 44px;
display: table;   

}

.nome{

font-size: 16px;
color: #666;
margin-top: 10px;  

}

.data-consulta{

text-align: left;
color: #d0002a;
margin: 10px;    
padding-left: 15px;
}

.space-p{

padding-right: 10px;   

}

.tamanho-nome{

min-width: 260px;    

}
.tamanho-vencimento{

padding-left: 30px !important;   


}

.status-tamanho{

margin: 10px;
color: #158175;
font-size: 16px;
padding-left: 15px;
font-family: arial;

}

.botao-do-status{


padding: 12px;
background: #fff;
border: 1px solid #158175;
color: #158175;
border-radius: 4px;
font-size: 13px;
min-width: 150px;  


}

.me-centra{

text-align: center;  

}

.ajusta-icone{

transform: rotate(180deg);
margin-left: 10px;


}


.wait{

color:#F2C832 !important;   
}

.danger{

color: #d0002a !important;   

}

.active{

border-left: 2px solid #158175;  


}

#sub-container{

background: #fff;
margin-top: 25px;
width: 100%;
border: 2px solid #E5E5E5;
border-bottom: 1px solid #E5E5E5;

}

.maior-profile{

border: 4px solid #1bb4a3;
border-radius: 80px;
width: 108px;
height: 108px;
display: table;
padding: 3px;   
margin-right: 25px;

}

.custo-consulta{

margin-left: 0px;
font-size: 13px !important;    


}


#outro-perfil{


padding-top: 55px;
display: table;
width: 45%;
padding-left: 40px;
float: left;
text-align: left;
padding-bottom: 30px;

}



#opcoes-bloco{

float: left;
width: 48%;
padding-left: 15px;
padding-top: 55px;

}


#rodape{

display: table;
width: 100%;
background: #f5f5f5;
border: 2px solid #E5E5E5;
border-top: 0px;   
margin-left: -2px;


}
#sub-rodape{

padding: 35px;
padding-top: 25px;    


}

#sub-next{

width: 30%;   

}


#sub-rodape a{
color: #1bb4a3;
}
.icon-adress{

width: 16px;
margin-right: 8px;
margin-top: -4px;    


}

.ico-map{

width: 23px;
margin-right: 7px;    


}

#outro-perfil h3{

font-size: 16px;
font-weight: bold;
color: #555;
white-space: nowrap; 
text-transform: uppercase;

}

.bb{

width: 55%;
float: left;

}

.sub-bb{

width: 44%;
float: right;    


}

.layout-bloco{

border: 2px solid #D6D6D6;
padding: 10px;
max-width: 86%;
width: 100%;
text-align: center;
border-radius: 4px;
min-height: 140px;  


}

.layout-bloco a{

color:#1bb4a3;    


}



.correct{

text-align: left;
padding-left: 15px;
color: #555;
}


.meu-bloco-botao{



background: url(../../../assets/skins/rounded_rectangle_4_ek1.png);
background-repeat: no-repeat;
display: table;
float: left;
padding: 15px;
color: #fff;
margin-left: 20px;
margin-top: 25px;
background-size: 100%;
text-align: center;
cursor: pointer;
padding-top:10px;
width: 123px;

}

.cancel-bloco-botao{


background: url('../../../assets/skins/rounded_rectangle_4_copy.png');
background-repeat: no-repeat;
display: table;
float: left;
padding: 15px;
color: #fff;
margin-left: 20px;
margin-top: 9px;
background-size: 100%;
text-align: center;
cursor: pointer;
padding-top:10px;
width: 123px;

}

#pago{

background: #007469;
color: #fff;
text-decoration: none;
padding: 6px;
border-radius: 4px;  
margin-left: 15px;

}

.destaque-verde{

color:#007469;
font-size:12px !important;



}

.destaque-vermelho{

color: #d0002a; 
font-size:12px !important;


}

.icon{

margin-top: -5px;  


}

.ptext{

float: right;
margin-top: -45px;
color: #7e8e9f;
text-transform: capitalize;   



}




.select{


width: 90px;
height: 40px;
-ms-border-radius: 20px;
-o-border-radius: 20px;
-moz-border-radius: 20px;
-webkit-border-radius: 20px;
border-radius: 20px;
background: rgba(219,225,232,255);
border: 0px;
padding-left: 10px;

}

.select{

-webkit-appearance: none;
-moz-appearance: none;
background: rgba(219,225,232,255) url(../../../assets/skins/down_2_ek1.png) 85.5% 50% / 13px no-repeat;



}

.modal-dialog{

float: right;
margin-top: 69px;
border-radius: 0px;   
width: 420px !important;
}

.modal-content{

border-radius: 0px;   
box-shadow: 0px 0px 0px !important;
border: 1px solid #fff !important;

}
.modal-backdrop {
top: 69px;
background-color: #D7DADD;   
}
.btn-primary{

display:none;   


}
.modal-footer{

border-top: 0px solid #e5e5e5;   

}

.modal-open .modal{


padding-right:0px !important;
right:0px !important;

}

.close{

font-size: 28px;
margin-right: -10px;   

}


.col-md-6 {
width: 40%;
margin-right: 0px;
padding-right: 0px;
}

#meu-bloco-aqui{


padding-left: 25px;    


}

.unico_label{

position: absolute;
margin-top: 2px;
z-index: 100;
font-size: 12px;
color: #555;
border-top: 0px solid #ccc;
margin-left: 0px;
padding-left: 25px;
width: 312px;

}
.unico_input{

border-top: 0px !important;
margin-top: 0px !important;
margin-left: -1px !important;
padding-top: 15px !important;
padding-left: 25px !important;  
color: #007469 !important;		        
}

#meulado{
    padding-top: 0px;
    

}
.myspace{
padding-top: 26px !important;
}
.linha_check{
border: 1px solid #ccc;
padding: 13px;
margin-left: 5px;
margin-top: 5px;
width: 290px;
height: 40px;
font-size: 13px;  
}

.imnot{

    float: left !important;
padding-right: 0px !important;


}

.meuselect{

    border-radius: 0px;
width: 286px;
padding: 11px 25px;
margin-left: 0px;
font-size: 13px;   



}

.troca_de_cor{

color:#555 !important;

}

.mylist{

padding: 10px !important;
margin-left: 2px !important;  
padding-left:25px !important;		        
}
#register-button {
width: 179px !important;
min-width: 179px !important;
max-width: 179px !important;
margin-left: 0px;
}

#outro-alert{


padding: 10px;
margin-bottom: 25px;
margin-top: 25px;
width: 720px;
text-align: center;
color: rgba(244,27,63,255);
background: #f1f1f1;



}





.borda-vermelha{

border-color:rgba(244,27,63,255) !important;    


}





#meunome{

text-transform: capitalize;
color: #007469;
font-weight: 100;    
padding-left: 0px;
margin-left: 0px;
margin-top: 0px;
}


#bloco_upload{


width: 98.5%;
background: #F9F9F9;
padding: 10px;
margin-bottom: 25px;
padding-top: 20px;


}

.profile{


float: left;
margin-left: 15px;
margin-top: 15px;
margin-right: 15px;
border: 4px solid #1bb4a3;
border-radius: 80px;
display: table;
background: #fff;
padding: 2px;
margin-top: -10px;
margin-right: 35px;

}

.checkbox {
    display:none;
}
.divcheckbox {
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    display:inline-block;
    cursor:pointer;
    background: #ccc url('');
}
 
.divcheckbox + .checkbox:checked {
    background:#158175 url('../../../assets/images/check.png');
    background-size:100%;
}
  

.btn-file {
position: relative;
overflow: hidden;
}
.btn-file {
position: absolute;
top: 0;
right: 0;
min-width: 100%;
min-height: 100%;
font-size: 100px;
text-align: right;
filter: alpha(opacity=0);
opacity: 0;
outline: none;
background: white;
cursor: inherit;
display: block;
}

.btn-default{

background: transparent !important;
color: #007469;
padding: 10px;
width: 180px;
height: inherit;
border: 1px solid #007469;


}

.ico{
width: 16px;
height: 16px;
margin-right: 5px;
margin-top: -2px;
}

.icon_user1{
background: url(../../../assets/skins/a-user.png);
z-index: 100;
position: absolute;
width: 17px;
height: 17px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 15px;
margin-left: 6px;
}


.icon_mail{

background:url('../../../assets/skins/a-email.png');
z-index: 100;
position: absolute;
width: 17px;
height: 17px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 12px;
margin-left: 6px;
}


.icon_data{

background:url('../../../assets/skins/a-data.png');
z-index: 100;
position: absolute;
width: 18px;
height: 18px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 15px;
margin-left: 6px;

}

.icon_tel{

background:url('../../../assets/skins/a-tel.png');
z-index: 100;
position: absolute;
width: 18px;
height: 18px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 15px;
margin-left: 6px;

}
.icon_vida{

background: url(../../../assets/skins/a-vida.png);
z-index: 100;
position: absolute;
width: 21px;
height: 21px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 15px;
margin-left: 5px;

}
.icon_lock1{

background:url('../../../assets/skins/a-lock.png');
z-index: 100;
position: absolute;
width: 18px;
height: 18px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 15px;
margin-left: 5px;
}

.icon_cep{

background:url('../../../assets/skins/a-cep.png');
z-index: 100;
position: absolute;
width: 18px;
height: 20px;
background-size: 100%;
background-repeat: no-repeat;
margin-top: 13px;
margin-left: 5px;
}
.maislinha{

margin-top: 17px !important;
margin-left: 6px !important;

}

#logo-2{

margin-left: 50px;
margin-top: -20px;


}
#conteudolateral {
  width: 74% !important;
  float: left !important;
  margin-left: 20% !important;
  display: table !important;
  margin-top: 2% !important;
  padding-left: 2% !important;
}

.myh12 {
  font-size: 19px;
  color: #555;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}

#subtext {
  margin-top: 0px;
  font-size: 14px;
  color: #666;
}

.myhr {
  border: 1px solid #d7dadd;
}

#bloco-upload {
  width: 98.5%;
  background: #f9f9f9;
  padding: 10px;
  margin-bottom: 25px;
  padding-top: 20px;
}

.profile {
  float: left;
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px;
  border: 4px solid #1bb4a3;
  border-radius: 80px;
  display: table;
  background: #fff;
  padding: 2px;
  margin-top: -10px;
  margin-right: 35px;
  width: 150px;
  height: 150px;
}

.document {
  float: left;
  /* margin-left: 15p x; */
  margin-top: 15px;
  margin-right: 15px;
  display: table;
  background: #fff;
  margin-top: -5px;
  /* width: 225px; */
  width: 240px;
  height: 150px;
}

#meunome {
  text-transform: capitalize;
  color: #007469;
  font-weight: 100;
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 0px;
}

.mybtn {
  background: transparent;
  color: #007469;
  padding: 10px;
  height: inherit;
  border: 1px solid #007469;
  overflow: hidden;
}

.unico-label2 {
  position: absolute;
  margin-bottom: 0px;
  z-index: 100;
  font-size: 12px;
  color: #555;
  border-top: 0px solid #ccc;
  margin-left: 0px;
  padding-left: 1%;
  width: 80%;
}

.unico-label {
  position: absolute;
  margin-bottom: 0px;
  z-index: 100;
  font-size: 12px;
  color: #555;
  border-top: 0px solid #ccc;
  margin-left: 0px;
  padding-left: 1%;
  width: 65.4%;
}

.unico-input { 
  color: #007469 !important;
}
  
.myinput {
  background-color: #fff;
  border: none;
  color: #0d0d0d;
  padding: 5% 7%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4%;
  width: 100% !important;
  border: 1px solid #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  text-align: left;
  width: 70% !important;
  padding: 2% 4%;
  font-size: 15px;
  border-radius: 0px;
}

.myinput:focus {
  background-color: 'black';
  border-bottom: 2px solid rgba(21, 129, 117, 255);
}
.myinput::placeholder {
  color: #cccccc;
}

.myinput2 {
  background-color: #fff;
  border: none;
  color: #0d0d0d;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  height: 60%;
  padding: 3% 10%;
  font-size: 15px;
  border-radius: 0px;
}

.myinput2:focus {
  background-color: 'black';
  border-bottom: 2px solid rgba(21, 129, 117, 255);
}
.myinput2::placeholder {
  color: #cccccc;
}

.myinput3 {
  background-color: #fff;
  border: none;
  color: #0d0d0d;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  text-align: left;
  width: 45%;
  height: 60%;
  padding: 2% 4%;
  border-radius: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

.myinput3:focus {
  background-color: 'black';
  border-bottom: 2px solid rgba(21, 129, 117, 255);
}
.myinput3::placeholder {
  color: #cccccc;
}

.icon-data {
  z-index: 100;
  position: absolute;
  width: 18px;
  height: 18px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 4%;
  margin-left: 1%;
  color: #007469 !important;
}

.icon-user1 {
  z-index: 100;
  position: absolute;
  width: 17px;
  height: 17px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 4%;
  margin-left: 1%;
  color: #007469 !important;
}

.icon-steto {
  z-index: 100;
  position: absolute;
  width: 17px;
  height: 17px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 12%;
  margin-left: 3%;
  color: #007469 !important;
}

.radio-my {
  background: #007469;
}

.radio-my:checked {
  background: #007469;
}

.imnot {
  float: right;
  padding-right: 1%;
  padding-top: 3%;
}

input#input_1_0 {
  display: none;
}
input#input_1_0 + label {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: inline-block;
  cursor: pointer;
}
input#input_1_0 + label {
  background: #ccc url('');
}
input#input_1_0:checked + label {
  background: #158175 url('../../../assets/images/check.png');
  background-size: 100%;
}
.linha-check {
  border: 1px solid #ccc;
  padding: 1%;
  width: 70%;
  font-size: 13px;
}

#but-right {
  margin-right: 10px;
}

.mydiv {
  height: 100%;
}

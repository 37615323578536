#topo{   
max-width: 100%;
background: #fff;
display: block;
width: 100%;
border-bottom: 1px solid rgba(188,199,211,255);
}

#logo{ 

width: 15%;
padding: 16px;
margin: 0%;
border-right: 1px solid rgba(188,199,211,255);
text-align: center;
float: left;
}

#painel{
float: left;
width: 50%;
height: 100%;    
}


.quadro{
vertical-align: middle;
float: left;
padding: 27px;
margin: 0%;
border-right: 1px solid rgba(188,199,211,255);   

}

#lateral{
   padding: 11px;
   width: 15%;
   float: right;
}

.navbar-nav{
   margin: 10px;
   height: 5vh;
}

.my-profile{
margin-right: 1em;
border: 2px solid #1bb4a3;
border-radius: 90%;
width: 3em;
height: 3em;
}

.p-left{

float: left;
width: 10%;
margin: 10px;
display: flex;
}


.next-title{
   margin-right: 3em;
   font-size: 15px;
   color: #555;   
   
}
.sub-title{
   
   font-size: 12px;
   color: #1bb4a3;
   
}

#corpo-botao{
   float: right;
   right: 0px;
   top: 0px;
   background: #ccc;
   padding: 27px;
   cursor: pointer;
   border-bottom: 1px solid #ccc;  
}

#corpo-botao:hover{
   color: white;
   background: #006C63;
   transition:100ms;  
}

.hovsidebar{
   display: none;
}

.linksidebar{
   color: #006C63;
   text-decoration: none;
}

#corpo-botao img {
   margin-top: 20px;
}

.novoquadro{
    padding: 8%;
    border-left: 1px solid rgba(188,199,211,255);   
    cursor: pointer;   
}

#botao-de-topo{
   
   background: url(../../assets/skins/rounded_rectangle_4_ek1.png);
   background-repeat: no-repeat;
   display: table;
   float: left;
   padding: 2%;
   color: #fff;
   margin-left: 3%;
   margin-top: 1.5%;
   background-size: 100%;
   text-align: center;
   cursor: pointer;    
   
}
#circle{

width: 1.5%;
height: 2.5%;
-ms-border-radius: 9px / 9px;
-o-border-radius: 9px / 9px;
-moz-border-radius: 9px / 9px;
-webkit-border-radius: 9px / 9px;
border-radius: 9px / 9px;
background: rgba(244,27,63,255);
position: absolute;
top: 13px;
margin-left: 7px;
color: #fff;
font-size: 11px;
text-align: center;

}
#icone-calendario{
float: left;
margin-right: 10px;   
}

.mleft{
   margin-left: 2em;
}

.nli2{
   width: 100%;
   padding-top: 3%;
   padding-bottom: 3%;  
   cursor:pointer;
   font-size: 14px;
}
   
.nli2:hover{
   background: #006C63;
   color:#fff;
   transition:500ms;
}

.botaosair{
   border-color: #006C63;
   color: #006C63;
}

.botaosair:hover{
    background: #006C63;
}


@media screen and (max-width: 767px) {
   .mleft {
       margin-left: 0em;
   }
   .next-title{
      margin-right: 0;
   }
   .my-profile{
      margin-top: 0.5em;
      margin-right: 0em;
   }
   
}


@media screen and (max-width: 575px) {

   .hovsidebar{
      display: block;
   }
}

#menu-lateral {
  width: 15vw;
  background: #007469;
  overflow: hidden;
  display: flex;
}

#sub-menu {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #91b3ae;
  padding-left: 0px;
  margin-top: 7.5%;
  overflow: hidden;
}

#sub-menu .nli {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 7%;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  padding-left: 10%;
  overflow: hidden;
}

.input-modal-link {
  display: block;
  border: 2px solid #006c63 !important;
  margin: 1rem 0;
  text-align: center;
  color: #353535;
}

.madal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.madal p {
  color: #006c63;
  font-weight: bold;
  font-size: 1.1rem;
}

.madal-btn {
  background-color: #006c63;
  border: #006c63;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
}

.madal-btn:hover,
.madal-btn:active,
.madal-btn:focus {
  background-color: #006c63 !important;
  border: #006c63 !important;
}

#sub-menu .nli:hover {
  background: #006c63;
  color: #fff;
  transition: 500ms;
}

.nomeicon {
  color: #fff;
  font-size: 1rem;
  margin-left: 7.5%;
}

@media screen and (max-width: 768px) {
  .nomeicon {
    display: none !important;
  }
  #menu-lateral {
    width: 8%;
  }
}

@media screen and (max-width: 575px) {
  #menu-lateral {
    display: none;
  }
}

/*.doctor-div{
    float: right;
    margin-right: 3%;
}

.patient-div{
    float:left;
    margin-left: 2%;
}



.OTSubscriberContainer {
    width: 80vh !important;
    height: 80vh !important;
  }

.macontainer{
    width: 70%;
    margin-left: 20%;
    margin-top: 1%;
}

.mycard-body{
    background: #ccc;
}

.cam-doctor{
    margin-top: 20%;
}

.mytextarea{
    width:100%;
    box-sizing:border-box;
    direction:rtl;
    display:block;
    max-width:100%;
    line-height:1.5;
    padding:15px 15px 30px;
    border-radius:3px;
    border:1px solid #F7E98D;
    font:13px Tahoma, cursive;
    transition:box-shadow 0.5s ease;
    box-shadow:0 4px 6px rgba(0,0,0,0.1);
}
*/

body {
  overflow-x: hidden;
}

.patient-div {
  background-color: rgb(185, 185, 185);
  position: absolute;
  margin-left: 2.2%;
  width: 97.8%;
  height: 100%;
}

.doctor-div {
  width: 20vh;
  height: 20vh;
  position: absolute;
  margin-left: 2.5%;
  margin-top: 0.1%;
  margin-right: 4%;
  margin-bottom: 4%;
  float: right;
  right: 0;
  bottom: 0;
  background-color: rgb(161, 161, 161);
  border: 3px solid #fff;
}

.OTSubscriberContainer {
  width: 100% !important;
  height: 100vh !important;
  border: 2px solid #ccc;
}

.OTPublisherContainer {
  width: 100% !important;
  height: 19vh !important;
  border: 2px solid #ccc;
}

.tokboxCall {
  width: 51.4%;
  height: 100%;
  position: absolute;
}

.tokboxdiv {
  margin-left: 14%;
  width: 100%;
  height: 100%;
  position: absolute;
}

.screen {
  margin-left: 51.5%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}

.sideForm {
  background-color: #ededed;
  border-left: 1px solid #ccc;
  max-width: 100%;
  width: 34.5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 767px) {
  .tokboxdiv {
    margin-left: 7%;
  }

  .sideForm {
    width: 55%;
  }
}

@media screen and (max-width: 575px) {
  .tokboxdiv {
    margin-left: 0%;
  }

  .sideForm {
    width: 60%;
  }
}

.cabecalho {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  font-size: 0.95vw;
}

.cabecalho > div {
  padding: 1px;
  flex-grow: 1;
  border-style: none none none solid;
  border-width: 1px;
  border-color: #ccc;
  margin-left: -1px;
}

.cabecalho > div > h5 {
  margin: 0;
  color: #555;
  font-size: small;
  margin-bottom: 5px;
  font-weight: bold;
}

.cabecalho > div > p {
  margin: 0;
  color: #555;
  font-size: small;
}

.cabecalho > div > p:hover {
  color: #007469;
}

/*Retirando formatação*/

.tabs .nav-link.active {
  color: none;
  background-color: none;
  border-color: transparent;
  /*overflow: scroll;*/
}

.nav-tabs .nav-link {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link {
  border: none;
  border-top-left-radius: none;
  border-top-right-radius: none;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-link {
  padding: 0;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #007469;
  background: none;
  border: 0;
  max-width: 100%;
}

a {
  color: #555;
}

a:hover {
  color: #007469;
}

/*Retirando formatação*/

.nav {
  color: #007469;
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0.6%;
  border-bottom: 1px;
  border-style: solid none solid none;
  border-width: 1px;
  border-color: #ccc;
  justify-content: center;
  font-size: xx-small;
}

.nav a {
  text-align: center;
  flex-grow: 1;
  padding: 0.5%;
  font-size: 11px;
  font-weight: bold;
  color: #555;
  max-width: 12%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav a:hover {
  color: #007469;
  max-width: 100%;
}

.rodape {
  width: 100%;
  bottom: 0;
  align-self: center;
  /* height: 5%; */
  padding: 1.5%;
  padding-right: 0px;
  border-style: solid none none none;
  border-width: 1px;
  border-color: #ccc;
  display: flex;
  /* flex: auto; */
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-top: 20px;
}

.cancelar {
  background-color: #d0002a !important;
  border-color: #d0002a !important;
}

.tab-content {
  flex-basis: 100%;
  width: 100%;
  display: flex;
  border-style: solid none none none;
  border-width: 1px;
  border-color: #ccc;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
}

.tab-content > .active {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0;
}

.umparteum {
  border-color: #007469;
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.umparteum > div {
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-left: 2.6%;
  margin-right: 2.4%;
  align-items: center;
}

.umparteum > div > p {
  flex-grow: 1;
  font-size: small;
  margin: 0;
  margin-left: 0.4%;
  margin-left: 0.3%;
  color: #555;
}

.umparteum > div > input {
  flex-grow: 1;
  width: 24.6%;
  border: 1px solid #007469;
  border-radius: 5px;
  color: #555;
  margin-right: 1%;
  font-size: small;
}

.boxPrescricao > div {
  display: flex;
  width: 100%;
  max-width: 100%;
  align-items: center;
  margin: 10px;
}

.boxPrescricao > div > p {
  flex-grow: 1;
  font-size: small;
  margin: 0;
  color: #555;
}

.boxPrescricao > div > input {
  flex-grow: 1;
  width: 24.6%;
  border: 1px solid #007469;
  border-radius: 5px;
  color: #555;
  margin-right: 1%;
  font-size: small;
}

.time {
  width: 13% !important;
}

.umpartedois {
  color: #555;
  margin-top: 20px;
  margin-left: 3%;
  margin-right: 3%;
  font-size: small;
}

.umpartedois > p {
  margin: 0;
}

.umpartetres {
  display: flex;
  margin-top: 10px;
  font-size: small;
}

.umpartetres > textarea {
  width: 100%;
  margin-left: 3%;
  margin-right: 3%;
  border: 1px solid #007469;
  background-color: #ffffff;
  border-radius: 5px;
  color: #555;
  font-size: small;
  padding: 1%;
}

.umparteseis {
  font-size: small;
  display: flex;
  margin-top: 20px;
}

.umparteseis > div {
  width: 100%;
  color: #555;
  margin-left: 3%;
  margin-right: 3%;
}

.umparteseis > div > p {
  margin: 0;
}

.umparteseis > div > textarea {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #007469;
  background-color: #ffffff;
  border-radius: 5px;
  color: #555;
  font-size: small;
  padding: 1.5%;
}

.umpartesete {
  display: flex;
  margin-top: 20px;
  margin-left: 3%;
  margin-right: 3%;
  width: 45%;
  color: #555;
  align-items: center;
}

.umpartesete > p {
  flex-grow: 1;
  margin: 0;
  font-size: small;
  color: #555;
}

.umpartesete > textarea {
  flex-grow: 1;
  font-size: small;
  padding: 1%;
  align-items: center;
  border: 1px solid #007469;
  background-color: #ffffff;
  border-radius: 5px;
  color: #555;
  margin-right: 2%;
}

.doispartedois {
  display: flex;
  margin-top: 10px;
}

.doispartedois > select {
  width: 100%;
  margin-left: 3%;
  margin-right: 3%;
  border: 1px solid #007469;
  background-color: #fff;
  border-radius: 5px;
  color: #555;
  font-size: small;
}

/* Tela de checkList */
.inputCheckBox {
  margin-left: 3%;
}

.umpartedoiss {
  display: flex;
  color: #555;
  margin-top: 20px;
}

.umpartedoiss > div {
  display: flex;
  width: 100%;
  margin-left: 3%;
  margin-right: 3%;
  align-items: center;
}

.umpartedoiss > div > p {
  margin: 0;
  font-size: small;
}

.separationForms {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 3%;
  margin-right: 3%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding-left: 1.7%;
  padding-right: 1.7%;
  padding-bottom: 1.7%;
}

.boxCheckbox {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 3%;
  margin-right: 3%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding-left: 1.7%;
  padding-right: 1.7%;
  padding-bottom: 1.7%;
}

.boxCheckbox > div {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1.7%;
}

.boxCheckbox > div > label {
  margin: 0;
  margin-left: 2%;
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

.boxCheckbox > div > input {
  margin: 0;
}

.boxCheckbox2 {
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 2%;
  padding-left: 2%;
}

.boxCheckbox2 > label {
  margin: 0;
  color: #555;
  font-size: small;
  width: 0%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  font-family: Arial, Helvetica, sans-serif;
}

.boxCheckbox2 > input {
  color: #555;
  border-color: #007469;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  font-size: small;
  margin-left: 1.5%;
  width: 11%;
  padding: 0.5%;
}

.boxCheckbox2 > .result {
  width: 35%;
}

.checkListObs {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 3%;
  margin-right: 3%;
}

.checkListObs > div {
  width: 100%;
}

.checkListObs > div > p {
  color: #555;
  font-size: small;
}

.checkListObs > div > textarea {
  color: #555;
  font-size: small;
  border-color: #007469;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 88%;
  padding: 1.5%;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 25px;
  height: 25px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  border-color: #007469;
  background-color: #007469;
}

.quatropartedois {
  margin-left: 3%;
  margin-right: 3%;
}

.quatropartedois > div {
  width: 100%;
}

.quatropartedois > div > table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
}

.quatropartedois > div > table > tbody > tr {
  padding: 1%;
  display: flex;
}

.quatropartedois > div > table > tbody > tr > td {
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #555;
}

.quatropartetres {
  display: flex;
  align-items: center;
  padding: 1%;
}

.quatropartetres > h5 {
  margin: 0;
  font-size: small;
  color: #007469;
}

.quatropartetres > button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.quatropartetres > button > i {
  color: #fff;
  margin-top: 1px;
  margin-right: 1px;
  padding: 0;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.card-body > h5 {
  font-size: small;
  font-weight: bold;
  margin: 0;
  color: #007469;
}

.card-body > form {
  display: flex;
  flex-direction: column;
}

.card-body > form > h5 {
  margin: 0;
  margin-top: 20px;
  font-size: small;
  color: #007469;
}

.card-body > form > div {
  display: flex;
  margin-top: 15px;
}

.card-body > form > div > div {
  display: flex;
  flex-direction: column;
}

.card-body > form > div > div > label {
  margin: 0;
  font-size: small;
  color: #555;
}

.form-group {
  margin: 0;
}

.col-md-12 {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.col-md-12 > div {
  width: 100%;
}

.card-body > form > div > div {
  width: 100%;
}

.card-body > form > div > div > label {
  font-size: small;
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
}

.btss {
  width: 100%;
  display: flex;
  padding: 1%;
  justify-content: center;
}

.btss > button {
  margin: 1%;
  font-size: small;
  background-color: #ededed;
  border-color: #007469;
  color: #007469;
}

.btss > button:hover {
  margin: 1%;
  background-color: #007469;
  border-color: #007469;
  color: #fff;
}

.card-body {
  width: 100%;
}

.form-label {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: small;
  font-family: Arial, Helvetica, sans-serif;
  color: #555;
}

.form-control {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

.btn-secondary {
  font-size: small;
}

.card {
  width: 100%;
}

.card > div {
  align-items: center;
  border: 0;
  display: flex;
  width: 100%;
}

.card > div > h5 {
  align-self: flex-start;
}

.card > div > div {
  border: 0;
  width: 100%;
}

.card > div > nav {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.card-body > form {
  width: 100%;
}

.tab-content {
  padding-bottom: 2%;
}

.containert {
  display: flex;
  width: 100%;
}

.containert > form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
}

.containert > form > div > div {
  width: 100%;
}

.containert > form > div > label {
  color: #555;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: small;
}

.containert > form > div > input {
  border: 1px solid #007469;
  background-color: #ffffff;
  border-radius: 5px;
  font-size: small;
}

.btDocs {
  margin: 0;
  margin-top: 2%;
  color: #007469;
  font-weight: bold;
  border: 0;
  font-size: small;
}

.fileCs {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.fileCs > input {
  border: 1px solid #007469;
  background-color: #ffffff;
  border-radius: 5px;
  margin-right: 3%;
  font-size: small;
}

.fileCs > button {
  background-color: #007469;
  color: #fff;
  border: 0;
  font-size: small;
}

.fileCs > button:hover {
  background-color: #007469;
  color: #fff;
  border: 0;
}

.row {
  flex-wrap: nowrap;
}

.ateste {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.ateste > h5 {
  font-size: small;
  color: #007469;
  font-weight: bold;
  margin: 0;
  margin-right: 4%;
}

.ateste > div {
  display: flex;
  align-items: center;
  width: 100%;
}

.ateste > div > p {
  margin: 0;
  margin-right: 3%;
  color: #555;
  font-size: small;
}

.ateste > div > span {
  margin: 0;
  margin-right: 3%;
}

.seteparteum {
  margin: 2%;
}

.seteparteum > h5 {
  margin: 0;
  color: #007469;
  font-size: small;
  font-weight: bold;
}

.tableResponsive {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
}

.tableResponsive > table {
  width: 96%;
  overflow: hidden;
}

.ticelula {
  font-size: x-small;
  color: #007469;
  margin: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cocelula {
  font-size: x-small;
  color: #555;
  margin: 0;
  align-items: center;
  text-align: center;
}

.table thead th {
  vertical-align: inherit;
  padding: 0.7%;
}

.table td,
.table th {
  padding: 1.7%;
}

input[type="file"] {
  display: initial !important;
}

.rowWbb {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: 3%;
  margin-right: 3%;
  font-size: small;
  align-items: left;
}

.absoluteList {
  position: absolute;
  width: 28vw;
  z-index: 5;
  border: 1px solid gray;
  margin-top: 5px;
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 60vh;
  background-color: white;
}

.pdfviewer {
  margin-top: 20px;
  width: 100%;
  height: 750px;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: 3%;
  margin-right: 3%;
  font-size: small;
  align-items: left;
}

.info-consulta {
  font-size: small;
  text-align: center;
  color: #555;
}

.container-button {
  position: absolute;
  bottom: 2vw;
  left: 35%;
  flex-direction: row;
}

.button-call {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  outline: none;
  width: 60px;
  height: 60px;
}

button.button-call:focus {
  outline: none;
}

.button-finish {
  margin-right: 10px;
}
.button-volume {
  background-color: gray;
  border-radius: 50px;
}

@media screen and (min-width: 750px) and (max-width: 1010px) {
  .container-button {
    position: absolute;
    bottom: 2vw;
    left: 15%;
    flex-direction: row;
  }
}

@media screen and (min-width: 300px) and (max-width: 750px) {
  .container-button {
    position: absolute;
    bottom: 150px;
    left: 15%;
    flex-direction: row;
  }
}

.subtitle{

font-size: 22px;
margin-top: 40px;   
    
}

#person{    
    font-size: 15px;
    font-family: Lato;
    color: #aaa; 
margin-bottom: 25px;
}
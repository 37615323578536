@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

#formContent {
  max-width: 600px !important;
  padding: 30px;
  padding-left: 25px;
  text-align: left;
}

#person {
  font-size: 15px;
  font-family: Lato;
  color: #aaa;
  text-align: center;
  margin-bottom: 20px;
}

.linecenter {
  white-space: nowrap !important;
}

#groupss {
  margin: 0px;
  max-width: 100%;
}

#register {
  float: left;
  display: table;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  width: 105px !important;
}

#type {
  width: 62px;
  padding-left: 0px;
  padding-right: 5px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #aaa;
  font-size: 14px;
  float: left;
}

#state {
  width: 48px;
  padding-left: 0px;
  padding-right: 5px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #aaa;
  font-size: 14px;
  float: left;
}

#rage {
  padding-right: 0px;
}

.imnot {
  float: right;
  padding-right: 55px;
}

#blocleft {
  margin-top: -97px;
  padding-right: 0px;
}

.newtype {
  /* width: 260px; */
  padding: 10px;
  text-align: center;
  color: #aaa;
  font-size: 12px;
  float: left;
}

.top {
  padding-top: 5px;
}

#color_gray {
  width: 100%;
  background: #eee;
  height: auto;
  /* display: table; */
}

#formContent {
  padding: 0px !important;
}

.myspace {
  padding: 30px;
  padding-left: 25px;
  padding-top: 10px;
}

.correct {
  width: 100%;
  display: table;
}

#register_button {
  min-width: 240px;
  margin-left: 10px;
}

.zero_left {
  padding-left: 0px !important;
}

.zero_right {
  padding-right: 0px !important;
}

/* .line_password {
  padding-left: 35px !important;
} */

.checkbox {
  display: none;
}
.divcheckbox {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  display: inline-block;
  cursor: pointer;
}
input#input_1_0 + label {
  background: #ccc url("");
}
input#input_1_0:checked + label {
  background: #158175 url("/src/assets/images/check.png");
  background-size: 100%;
}

#but_right {
  margin-right: 10px;
}

#but_top {
  margin-top: -4px;
}

.exemplo1 input[type="radio"] {
  display: none; /* Esconde os inputs */
}

.exemplo1 input[type="radio"] + label {
  display: inline-block;
  height: 20px;
  padding: 0 0 0 25px;
  margin: 0 10px 0 0;
  background-image: url(../../../assets/images/ico-master.png);
  background-repeat: no-repeat;
  background-position: 0 0;
}

.exemplo1 input[type="radio"]:checked + label {
  background-position: 0 -30px;
}

.add {
  float: left;
  width: 50px;
}

.exemplo1 {
  width: 100%;
  display: table;
  padding-left: 15px;
}

.arquivo {
  display: none;
}
input[type="file"] {
  display: none;
}
#file,
.file {
  box-sizing: border-box;
  font-size: 15px;
  vertical-align: middle;
  width: 300px;
}
.btn {
  border: none;
  box-sizing: border-box;
  padding: 2px 10px;
  background-color: #4493c7;
  color: #fff;
  height: 32px;
  font-size: 15px;
  vertical-align: middle;
}
#myupload {
  min-width: 60px !important;
  padding: 10px;
  padding-top: 7px;
  margin-top: -82px;
  margin-left: 170px;
  border-radius: 0px;
  background: #ccc;
  border-bottom: 2px solid #aaa;
  color: #555;
}
#okfile {
  margin-top: -15px;
}

#register_button {
  min-width: 250px;
  width: 250px;
  max-width: 250px;
}

#hide {
  display: none;
}

.seta-cima {
  position: absolute;
  margin-top: -2px;
  margin-left: 47px;
}

/**
*** Seta para ESQUERDA
**/
.seta-esquerda:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid blue;
}

/**
*** Seta para DIREITA
**/
.seta-direita:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid green;
}

/**
*** Seta para CIMA
**/
.seta-cima:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;

  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #0e6c62;
}

/**
*** Seta para BAIXO
**/
.seta-baixo:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 0;
  height: 0;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f00;
}

/* .testebotao {
  margin-top: 20px;
} */

.myinputcomponent {
  background-color: #fff;
  border: none;
  color: #0d0d0d;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 5px;
  width: 100% !important;
  border: 1px solid #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  text-align: left;
}

.myinputcomponent:focus {
  background-color: #fff;
  border-bottom: 2px solid rgba(21, 129, 117, 255);
}

.myinputcomponent::placeholder {
  color: #cccccc;
}

#botao-de-topo{
   
    background: url(../../assets/skins/rounded_rectangle_4_ek1.png);
    background-repeat: no-repeat;
    display: table;
    float: left;
    padding: 2%;
    color: #fff;
    margin-left: 3%;
    margin-top: 1.5%;
    background-size: 100%;
    text-align: center;
    cursor: pointer;    
    
 }

.botaotop {
    display: inline-block;
    padding: 0.7em 1.5em;
    color: #fff;
    margin-bottom: 1.5%;
    background-size: 100%;
    text-align: center;
    cursor: pointer;
    background: #007469;
}

.botaotop:hover{
    background: #006C63;
}

.botaotop:active{
    background-color: #006C63;
}

.modaltitulo {
    text-transform: uppercase;
    color: #007469;
    margin: 0 auto;
    font-size: 21px;
}

.horariotitle{
    font-size: 14px;
    margin: 0 auto;
}

.horariodia{	    
    background: #F6F6F6;
    border: 1px solid #ccc;
    display: table;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    margin-bottom: 2%;
}


.submarca{    
    padding: 2%;
    float: left;
    width: 100%;
    padding-bottom: 1%;
    padding-top: 3%;
    height: 100%;
    margin-bottom: 0px;   
    text-align: left;    
}

.acoes{
    padding-top: 2%;
    margin-top: 0%;
    margin-right: 3%;   
    float: right;
    cursor: pointer;   
}

.matable {
    width:100%;
}

.textInfo {
    max-width: 23%;
    float: left;
    text-align: left;
    padding: 1.5% 1.5%;
    padding-left: 0;
    font-size: 16px;
    margin: 0;
}

#menu-semana{
    float: right;
    width: 77%;
    margin: 0 auto; 
}

.botaosemana{
    display: none;
}

.botao-semana{
    width: 14.2%;
    float: left;
    font-size: 14.9px;
    background: #eee;
    padding: 1.5% 1.5%;
    font-weight: normal;
    text-align: center;
    color: #007469;
    cursor: pointer;
}

.botaosemana:checked ~.botao-semana{
    color: white;
    background: #007469;
}

.nova-agenda{
    border: 2px solid #ccc;
    display: table;
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
    padding-bottom: 12px;
}
 
.nova-agenda-titulo{
    color: #007469;
    font-size: 15px;    
    text-transform: uppercase;
}

#linha-formulario{  
    margin-top: 20px;
    width: 100%;
    display: table;
    float: left;
    margin-bottom: 0px;   
}

.boxhorario {
    display: flex;
    flex-wrap: nowrap;
    width: 77%;
}


.minhas{
    flex-basis: 50%;
    padding-left: 12%;
    padding-right: 12%;
    width: 15%;
    float: left;
    text-align: center;
}

.passa{
    margin-left: 1%;
    width: 10%;
    margin-right: 3%; 
   
}
.passou{
   margin-right: 20%;
}

.passando{
   margin-left: 0px;   
}


.menor{
    
    width: 100%;
}


.label {
    margin: 0;
    margin-bottom: 0.7%;
    width: 100%;
    color: #007469;
    white-space: nowrap !important;
    text-align: center;
    font-size: 120%;
}

.personalizado{
    background-color: #fff;
    color: #0d0d0d;
    padding: 10% 8%;
    margin-top: 10%;
    border: 1px solid #ccc;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    font-size: 100%;
    margin-bottom: 20%;
}

.boxadd {
    width: 100%;
    max-width: 90vw;
    display: flex;
    justify-content: flex-end;
}

.posicionabotao{
}

.posicionaerro{/*
    margin-left: 7%;
    margin-bottom: 2%;
    padding-top: 10%;*/
}


.mybotao{
    background: #007469;
}

.mybotao:hover{
    background: #006C63;
}

.mybotao:active{
    background: #006C63;
}

.fundo{
    background: #ccc;
}

.mytrash{
    cursor: pointer;

}

.titleHC {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 125%;
    text-align: center;
}

.pc-tab > input,
.pc-tab section > div {
    display: none;
}

#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3,
#tab4:checked ~ section .tab4,
#tab5:checked ~ section .tab5,
#tab6:checked ~ section .tab6,
#tab7:checked ~ section .tab7 {
    display: block;
}

*, *:after, *:before{
    box-sizing: border-box;
}

.pc-tab2{
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
}

.pc-tab{
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    border-radius: 4px;
    padding: 1rem;
}

.pc-tab ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.pc-tab ul li label{
    max-width: 14.285999999%;
    float: left;
    margin: 0px;
    padding: 1.5% 1.5%;
    font-weight: normal;
    font-size: unset;
    text-align: center;
    text-justify: auto;
    background-color: #eeeeee;
    color: #007469;
    display: unset;
    cursor: pointer;
}

.pc-tab ul li label:hover{
    background-color: #ddd;
}

.pc-tab ul li label:active{
    background-color: #fff;
}

.pc-tab ul li:not(:last-child) label{
    border-right-width: 0;
}

.pc-tab section{
    clear: both;
}

.pc-tab section div{
    width: 100%;
    max-width: 90vw;
    /*border: 1px solid #ccc;*/
    background-color: #fff;
    color: #444;
}

.pc-tab section div h2{
    margin: 0;
    letter-spacing: 1px;
    color: #34495e;
}

#tab1:checked ~div .tab1 label,
#tab2:checked ~div .tab2 label,
#tab3:checked ~div .tab3 label,
#tab4:checked ~div .tab4 label,
#tab5:checked ~div .tab5 label,
#tab6:checked ~div .tab6 label,
#tab7:checked ~div .tab7 label{
    background-color: #006C63;
    color: #fff;
    position: relative;
}

#tab1:checked ~div .tab1 label:after,
#tab2:checked ~div .tab2 label:after,
#tab3:checked ~div .tab3 label:after,
#tab4:checked ~div .tab4 label:after,
#tab5:checked ~div .tab5 label:after,
#tab6:checked ~div .tab6 label:after,
#tab7:checked ~div .tab7 label:after{
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #006C63;
    left: 0;
    bottom: -1px;
}

.space {
    border: 1px solid #fff;
    height: 20px;
}

.semana{
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 90vw;
    border: 2px solid #eeeeee;
    margin-bottom: 2%;
}

.semana > div {
    width: 100px;
    margin: 0.7%;
    line-height: 90%;
    font-size: 30px;
}

.iconeCalendario {
    background: url(icons/calendar.png);
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: center;
    flex-basis: 25%;
}

.intervaloHorario > p {
    font-size: 60%;
    text-align: center;
    margin: 0;
}

.iconeExcluir {
    background: url(icons/garbage.png);
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: center;
    margin: 0 !important;
    flex-basis: 25%;
    cursor: pointer;
}

.pagination {
    width: 100%;
    max-width: 90vw;
    justify-content: center;
    flex-direction:row;
}

.page-item.active .page-link  {
    z-index: 1;
    color: #fff;
    background-color: #006C63;
    border: 0;
    border-left: 0;
}

.page-item.active {
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #006C63;
    background-color: #ddd;
    border: 1px solid #ddd;
    border: 0;
}

.pc-tab ul{
    max-width: 90vw;
}